<div class="container">
  <div class="{{ configSetting.color }}_filter_container">
    <div class="filter_container_info"></div>
    <form class="filter_container_form" #mainForm>
      <label style="width: 22%" class="search_svg_container">
        <svg class="search_svg" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.7272 17.0788L13.2881 12.4355C14.3889 11.1154 15.0091 9.45702 15.0463 7.73428C15.0835 6.01155 14.5354 4.32761 13.4925 2.96072C12.4497 1.59383 10.9746 0.625792 9.31089 0.216573C7.6472 -0.192646 5.89454 -0.0185567 4.34251 0.710073C2.79047 1.4387 1.53196 2.67827 0.774929 4.22393C0.0178962 5.7696 -0.192349 7.52887 0.178934 9.21103C0.550217 10.8932 1.48081 12.3976 2.81693 13.4756C4.15306 14.5536 5.81475 15.1407 7.52744 15.1399C9.0714 15.1441 10.5784 14.6651 11.8397 13.7692L16.3116 18.4454C16.4027 18.5421 16.5125 18.619 16.6342 18.6714C16.7559 18.7238 16.887 18.7505 17.0194 18.75C17.2117 18.7497 17.3997 18.6927 17.56 18.5859C17.7204 18.4791 17.846 18.3273 17.9214 18.1493C17.9968 17.9714 18.0186 17.775 17.984 17.5847C17.9495 17.3944 17.8602 17.2185 17.7272 17.0788ZM7.52744 1.96762C8.62794 1.96762 9.70374 2.29595 10.6188 2.9111C11.5338 3.52624 12.247 4.40056 12.6682 5.42351C13.0893 6.44645 13.1995 7.57207 12.9848 8.65802C12.7701 9.74398 12.2401 10.7415 11.462 11.5244C10.6838 12.3073 9.69233 12.8405 8.61297 13.0565C7.53361 13.2725 6.41482 13.1617 5.39808 12.738C4.38135 12.3142 3.51233 11.5967 2.90092 10.6761C2.28951 9.75545 1.96317 8.67309 1.96317 7.56586C1.96425 6.08145 2.55083 4.65815 3.5941 3.60851C4.63737 2.55888 6.05203 1.96871 7.52744 1.96762Z"
            fill="#C4C4C4"
          />
        </svg>
        <input
          type="text"
          class="{{ configSetting.color }}_main-input"
          #researcher
          timeInput
          [time]="500"
          (timeInput)="loadEventWithResearcher(researcher.value)"
          style="padding: 7px 15px; padding-left: 34px !important; color: #949494"
        />
      </label>
      <label style="width: 19%">
        <select
          class="{{ configSetting.color }}_main-select"
          [(ngModel)]="filterCity"
          [ngModelOptions]="{ standalone: true }"
          (change)="changeFilterCity(filterCity)"
        >
          <option value="undefined" disabled>
            {{ 'return.city' | translate }}
          </option>
          <option value="undefined">{{ 'events.city' | translate }}</option>
          <option *ngFor="let one of allCities" [ngValue]="one.id" valueByLang [dictionary]="one.dictionary"></option>
        </select>
      </label>
      <label style="width: 19%">
        <select
          class="{{ configSetting.color }}_main-select"
          [(ngModel)]="filterCategory"
          [ngModelOptions]="{ standalone: true }"
          (change)="loadEventWithFilter()"
        >
          <option value="undefined" disabled>
            {{ 'return.category' | translate }}
          </option>
          <option value="undefined">{{ 'events.category' | translate }}</option>
          <option *ngFor="let one of categories" [ngValue]="one.id" valueByLang [dictionary]="one.name"></option>
        </select>
      </label>
      <label style="width: 19%">
        <select
          class="{{ configSetting.color }}_main-select"
          [(ngModel)]="filterPlatformHall"
          [ngModelOptions]="{ standalone: true }"
          (change)="loadEventWithFilter()"
        >
          <option value="undefined" disabled>
            {{ 'return.ploschadka' | translate }}
          </option>
          <option value="undefined">
            {{ 'events.ploschadka' | translate }}
          </option>
          <option *ngFor="let one of platformHalls" [ngValue]="one.id" valueByLang [dictionary]="one.name"></option>
        </select>
      </label>
      <label style="width: 19%; position: relative" class="label_btn label_btnn">
        <div style="width: 100%; height: 100%" (click)="openCloseBlock(calendar)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.125 1.25H13.375V0H12.125V1.25H3.875V0H2.625V1.25H1.875C0.841125 1.25 0 2.09113 0 3.125V14.125C0 15.1589 0.841125 16 1.875 16H14.125C15.1589 16 16 15.1589 16 14.125V3.125C16 2.09113 15.1589 1.25 14.125 1.25ZM14.75 14.125C14.75 14.4696 14.4696 14.75 14.125 14.75H1.875C1.53038 14.75 1.25 14.4696 1.25 14.125V5.875H14.75V14.125ZM14.75 4.625H1.25V3.125C1.25 2.78038 1.53038 2.5 1.875 2.5H2.625V3.75H3.875V2.5H12.125V3.75H13.375V2.5H14.125C14.4696 2.5 14.75 2.78038 14.75 3.125V4.625Z"
              fill="#727272"
            />
            <path d="M3.625 7.1875H2.375V8.4375H3.625V7.1875Z" fill="#727272" />
            <path d="M6.125 7.1875H4.875V8.4375H6.125V7.1875Z" fill="#727272" />
            <path d="M8.625 7.1875H7.375V8.4375H8.625V7.1875Z" fill="#727272" />
            <path d="M11.125 7.1875H9.875V8.4375H11.125V7.1875Z" fill="#727272" />
            <path d="M13.625 7.1875H12.375V8.4375H13.625V7.1875Z" fill="#727272" />
            <path d="M3.625 9.6875H2.375V10.9375H3.625V9.6875Z" fill="#727272" />
            <path d="M6.125 9.6875H4.875V10.9375H6.125V9.6875Z" fill="#727272" />
            <path d="M8.625 9.6875H7.375V10.9375H8.625V9.6875Z" fill="#727272" />
            <path d="M11.125 9.6875H9.875V10.9375H11.125V9.6875Z" fill="#727272" />
            <path d="M3.625 12.1875H2.375V13.4375H3.625V12.1875Z" fill="#727272" />
            <path d="M6.125 12.1875H4.875V13.4375H6.125V12.1875Z" fill="#727272" />
            <path d="M8.625 12.1875H7.375V13.4375H8.625V12.1875Z" fill="#727272" />
            <path d="M11.125 12.1875H9.875V13.4375H11.125V12.1875Z" fill="#727272" />
            <path d="M13.625 9.6875H12.375V10.9375H13.625V9.6875Z" fill="#727272" />
          </svg>
          <span class="calendar_text">{{ 'events.calendar_filter' | translate }}</span>
        </div>
        <div class="container_pop_up_calendar" style="display: none" #calendar>
          <span
            *ngFor="let one of loadRelevantMonths()"
            (click)="filterMonth = one; loadEventWithFilterMonth()"
            [ngClass]="{ active: filterMonth == one }"
          >
            {{ 'month_naz_vidminok.' + one | translate }}
          </span>
        </div>
      </label>
      <label style="width: 25%; margin-bottom: 0 !important">
        <input
          type="date"
          [(ngModel)]="filterDateFrom"
          [ngModelOptions]="{ standalone: true }"
          (change)="setDefaultDataToInput(false); loadEventWithFilterDate()"
          class="{{ configSetting.color }}_main-input"
          style="padding: 4.5px 15px; border: 0.5px solid #e5e5e5 !important"
        />
      </label>
      <label style="width: 25%; margin-bottom: 0 !important">
        <input
          type="date"
          [(ngModel)]="filterDateTo"
          [ngModelOptions]="{ standalone: true }"
          (change)="setDefaultDataToInput(true); loadEventWithFilterDate()"
          class="{{ configSetting.color }}_main-input"
          style="padding: 4.5px 15px; border: 0.5px solid #e5e5e5 !important"
        />
      </label>

      <div
        style="width: 30%; margin-bottom: 0 !important; padding: 0 !important"
        class="label_btn {{ configSetting.color }}_label_btn_long"
      >
        <div
          (click)="openCloseBlock(alphabet)"
          style="
            padding: 6px 15px;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span class="select_text">Сортування</span>
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 10L0.870835 0.249999L12.1292 0.25L6.5 10Z" fill="#000000" />
          </svg>
        </div>
        <div class="container_pop_up_alphabet" #alphabet style="display: none">
          <label
            style="width: 49%; cursor: pointer; border-right: 1px solid #c4c4c4; margin-bottom: 0; text-align: center; padding-bottom: 5px"
          >
            <input
              type="radio"
              name="alphabet"
              (change)="byDate = false; sort = 'ASC'; loadEventWithFilter()"
              [checked]="sort == 'ASC' && !byDate"
            />
            Алфавіт {{ 'aZ' | translate }}
          </label>
          <label style="width: 49%; cursor: pointer; margin-bottom: 0; text-align: center; padding-bottom: 5px">
            <input
              type="radio"
              name="alphabet"
              (change)="byDate = false; sort = 'DESC'; loadEventWithFilter()"
              [checked]="sort == 'DESC' && !byDate"
            />
            Алфавіт {{ 'zA' | translate }}
          </label>
          <label style="width: 98%; cursor: pointer; padding-top: 5px; text-align: center; border-top: 1px solid #c4c4c4">
            <input type="radio" (change)="byDate = true; loadEventWithFilter()" [checked]="byDate" />
            По даті
          </label>
        </div>
      </div>
      <label style="width: 19%; margin-bottom: 0 !important" class="label_btn label_btn_reset" (click)="resetFilter()">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.8782 5.42088L14.3242 2.73345C14.1549 2.4232 13.7894 2.261 13.4062 2.40264L11.6502 3.10814C11.3166 2.86802 10.966 2.66402 10.6033 2.50039L10.3362 0.6375C10.2909 0.274125 9.97572 0 9.60311 0H6.48643C6.11386 0 5.79863 0.274125 5.75401 0.631125L5.48626 2.4997C5.13493 2.65907 4.78995 2.86025 4.44145 3.10814L2.68059 2.40055C2.34202 2.27023 1.93827 2.41686 1.76966 2.72498L0.212737 5.41732C0.0257369 5.73464 0.102934 6.1462 0.393362 6.3757L1.88156 7.53877C1.85818 7.76332 1.84686 7.95952 1.84686 8.14582C1.84686 8.33212 1.85818 8.52832 1.88086 8.75288L0.391934 9.91668C0.107881 10.1412 0.0299869 10.5393 0.211309 10.8708L1.76538 13.5589C1.93468 13.8685 2.30088 14.0307 2.68408 13.889L4.44003 13.1835C4.77365 13.4236 5.12428 13.6276 5.48695 13.7912L5.75401 15.6534C5.79863 16.0175 6.11383 16.2916 6.48643 16.2916H8.92736C9.05981 16.2916 9.18024 16.218 9.24117 16.1011C9.30209 15.9842 9.29286 15.8433 9.21779 15.7349C8.67591 14.9586 8.38904 14.0483 8.38904 13.1041C8.38904 12.6636 8.45349 12.2223 8.58099 11.7923C8.61356 11.6818 8.59091 11.5628 8.51936 11.4721C8.44781 11.3822 8.33804 11.334 8.22256 11.3376C8.18431 11.3397 8.14676 11.3446 8.05043 11.3567C6.28881 11.3567 4.85586 9.92375 4.85586 8.16213C4.85586 6.4005 6.28881 4.96682 8.05043 4.96682C9.81206 4.96682 11.245 6.39977 11.2386 8.2202C11.2337 8.25775 11.2287 8.29527 11.2266 8.33352C11.2209 8.44897 11.2712 8.55947 11.3612 8.63032C11.4518 8.70187 11.5715 8.72313 11.6813 8.69195C12.3705 8.48652 13.0498 8.43764 13.8191 8.5772C13.9133 8.5942 14.0167 8.5517 14.096 8.49289C14.1747 8.43409 14.2271 8.32502 14.2377 8.22727C14.2391 8.21452 14.2427 8.15714 14.2427 8.14439C14.2427 7.92409 14.2271 7.71657 14.2087 7.53734L15.6969 6.375C15.981 6.15118 16.0589 5.75307 15.8782 5.42088Z"
            fill="#72002D"
          />
        </svg>
        <span style="color: #fff" class="calendar_text">{{ 'events.reset_filter' | translate }}</span>
      </label>
    </form>
  </div>
  <div class="title_container">
    <h2 class="main-h2">{{ 'events.event_title' | translate }}</h2>
    <div class="container_cards_up_title_filter">
      <span
        [ngClass]="{ active_up_filter_type: filterEventFilterType == 'ALL' }"
        (click)="filterEventFilterType = 'ALL'; loadEventWithFilterEventType()"
        class="container_cards_up_title_filter_item"
      >
        {{ 'sortTypeFilter.all' | translate }}
      </span>
      <span
        [ngClass]="{ active_up_filter_type: filterEventFilterType == 'OWN' }"
        (click)="filterEventFilterType = 'OWN'; loadEventWithFilterEventType()"
        class="container_cards_up_title_filter_item"
      >
        {{ 'sortTypeFilter.popular' | translate }}
      </span>
      <span
        [ngClass]="{
          active_up_filter_type: filterEventFilterType == 'FOREIGN'
        }"
        (click)="filterEventFilterType = 'FOREIGN'; loadEventWithFilterEventType()"
        class="container_cards_up_title_filter_item"
      >
        {{ 'sortTypeFilter.other' | translate }}
      </span>
      <span
        [ngClass]="{
          active_up_filter_type: filterEventFilterType == 'THIS_WEEK'
        }"
        (click)="filterEventFilterType = 'THIS_WEEK'; loadEventWithFilterEventType()"
        class="container_cards_up_title_filter_item"
      >
        {{ 'sortTypeFilter.this_week' | translate }}
      </span>
      <span
        [ngClass]="{ active_up_filter_type: filterEventFilterType == 'TODAY' }"
        (click)="filterEventFilterType = 'TODAY'; loadEventWithFilterEventType()"
        class="container_cards_up_title_filter_item"
      >
        {{ 'sortTypeFilter.today' | translate }}
      </span>
      <span
        [ngClass]="{
          active_up_filter_type: filterEventFilterType == 'TOMORROW'
        }"
        (click)="filterEventFilterType = 'TOMORROW'; loadEventWithFilterEventType()"
        class="container_cards_up_title_filter_item"
      >
        {{ 'sortTypeFilter.tomorrow' | translate }}
      </span>
      <span
        [ngClass]="{
          active_up_filter_type: filterEventFilterType == 'THIS_WEEKEND'
        }"
        (click)="filterEventFilterType = 'THIS_WEEKEND'; loadEventWithFilterEventType()"
        class="container_cards_up_title_filter_item"
      >
        {{ 'sortTypeFilter.this_weekend' | translate }}
      </span>
      <span
        [ngClass]="{
          active_up_filter_type: filterEventFilterType == 'THIS_MONTH'
        }"
        (click)="filterEventFilterType = 'THIS_MONTH'; loadEventWithFilterEventType()"
        class="container_cards_up_title_filter_item"
      >
        {{ 'sortTypeFilter.this_month' | translate }}
      </span>
    </div>
  </div>
  <div class="index_container">
    <div
      class="container_small_without_date"
      *ngIf="events && configSetting && configSetting.date == 'WITHOUT' && configSetting.line == 'FOUR'"
    >
      <app-event-one-small
        *ngFor="let one of events.content; trackBy: trackById"
        [eventData]="one"
        (moreInfo)="openPopUp($event)"
        class="container_small_without_date_one"
      ></app-event-one-small>
    </div>
    <div
      class="container_small_with_date"
      *ngIf="eventsByDate && configSetting && configSetting.date == 'WITH' && configSetting.line == 'FOUR'"
    >
      <div *ngFor="let one of eventsByDate; trackBy: trackById">
        <div class="container_small_with_date_date">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                d="M2.75095 3.43758V0.687506C2.75095 0.307458 3.0584 0 3.43854 0C3.8185 0 4.12596 0.307458 4.12596 0.687506V3.43758C4.12596 3.81753 3.8185 4.12499 3.43854 4.12499C3.0584 4.12504 2.75095 3.81753 2.75095 3.43758ZM14.4386 4.12504C14.8184 4.12504 15.1261 3.81758 15.1261 3.43762V0.687506C15.1261 0.307458 14.8184 0 14.4386 0C14.0585 0 13.7508 0.307458 13.7508 0.687506V3.43758C13.7508 3.81753 14.0585 4.12504 14.4386 4.12504ZM22.0009 16.4999C22.0009 19.5375 19.5383 22.0001 16.5009 22.0001C13.4637 22.0001 11.001 19.5375 11.001 16.4999C11.001 13.4627 13.4636 11 16.5009 11C19.5383 11 22.0009 13.4626 22.0009 16.4999ZM20.6261 16.4999C20.6261 14.2255 18.7757 12.3749 16.5009 12.3749C14.2264 12.3749 12.376 14.2255 12.376 16.4999C12.376 18.7747 14.2264 20.625 16.5009 20.625C18.7757 20.625 20.6261 18.7747 20.6261 16.4999ZM5.50101 8.25003H2.75095V11H5.50101V8.25003ZM2.75095 15.125H5.50101V12.3749H2.75095V15.125ZM6.87593 11H9.62603V8.25003H6.87593V11ZM6.87593 15.125H9.62603V12.3749H6.87593V15.125ZM1.37603 16.3632V6.87497H16.5009V9.62504H17.876V4.26206C17.876 3.42683 17.2098 2.74998 16.3869 2.74998H15.8134V3.43762C15.8134 4.19486 15.197 4.81259 14.4386 4.81259C13.68 4.81259 13.0636 4.19486 13.0636 3.43762V2.74998H4.81342V3.43762C4.81342 4.19486 4.1971 4.81259 3.43859 4.81259C2.6799 4.81259 2.06353 4.19486 2.06353 3.43762V2.74998H1.49079C0.66776 2.74998 0.000976562 3.42683 0.000976562 4.26206V16.3632C0.000976562 17.1957 0.66776 17.8752 1.49079 17.8752H9.62603V16.4999H1.49079C1.42849 16.4999 1.37603 16.4355 1.37603 16.3632ZM13.7508 11V8.25003H11.001V11H13.7508ZM18.5635 16.4999H16.5009V14.4373C16.5009 14.0575 16.1934 13.75 15.8134 13.75C15.4333 13.75 15.1261 14.0575 15.1261 14.4373V17.1876C15.1261 17.5675 15.4333 17.8751 15.8134 17.8751H18.5635C18.9435 17.8751 19.251 17.5675 19.251 17.1876C19.251 16.8076 18.9435 16.4999 18.5635 16.4999Z"
                fill="#3473AE"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>{{ 'day_full.' + (one.date | localDayOfTheWeek) | translate }}</span>
          <span>{{ one.date | localdate }}</span>
        </div>
        <div class="container_small_without_date">
          <app-event-one-small
            *ngFor="let two of one.events; trackBy: trackById"
            [eventData]="two"
            (moreInfo)="openPopUp($event)"
            class="container_small_without_date_one"
          ></app-event-one-small>
        </div>
      </div>
    </div>
    <div
      class="container_big_without_date"
      *ngIf="events && configSetting && configSetting.date == 'WITHOUT' && configSetting.line == 'ONE'"
    >
      <app-event-one-big
        *ngFor="let one of events.content; trackBy: trackById"
        [eventData]="one"
        (moreInfo)="openPopUp($event)"
        class="container_big_without_date_one"
      ></app-event-one-big>
    </div>
    <div
      class="container_big_with_date"
      *ngIf="eventsByDate && configSetting && configSetting.date == 'WITH' && configSetting.line == 'ONE'"
    >
      <div *ngFor="let one of eventsByDate">
        <div class="container_big_with_date_date">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                d="M2.75095 3.43758V0.687506C2.75095 0.307458 3.0584 0 3.43854 0C3.8185 0 4.12596 0.307458 4.12596 0.687506V3.43758C4.12596 3.81753 3.8185 4.12499 3.43854 4.12499C3.0584 4.12504 2.75095 3.81753 2.75095 3.43758ZM14.4386 4.12504C14.8184 4.12504 15.1261 3.81758 15.1261 3.43762V0.687506C15.1261 0.307458 14.8184 0 14.4386 0C14.0585 0 13.7508 0.307458 13.7508 0.687506V3.43758C13.7508 3.81753 14.0585 4.12504 14.4386 4.12504ZM22.0009 16.4999C22.0009 19.5375 19.5383 22.0001 16.5009 22.0001C13.4637 22.0001 11.001 19.5375 11.001 16.4999C11.001 13.4627 13.4636 11 16.5009 11C19.5383 11 22.0009 13.4626 22.0009 16.4999ZM20.6261 16.4999C20.6261 14.2255 18.7757 12.3749 16.5009 12.3749C14.2264 12.3749 12.376 14.2255 12.376 16.4999C12.376 18.7747 14.2264 20.625 16.5009 20.625C18.7757 20.625 20.6261 18.7747 20.6261 16.4999ZM5.50101 8.25003H2.75095V11H5.50101V8.25003ZM2.75095 15.125H5.50101V12.3749H2.75095V15.125ZM6.87593 11H9.62603V8.25003H6.87593V11ZM6.87593 15.125H9.62603V12.3749H6.87593V15.125ZM1.37603 16.3632V6.87497H16.5009V9.62504H17.876V4.26206C17.876 3.42683 17.2098 2.74998 16.3869 2.74998H15.8134V3.43762C15.8134 4.19486 15.197 4.81259 14.4386 4.81259C13.68 4.81259 13.0636 4.19486 13.0636 3.43762V2.74998H4.81342V3.43762C4.81342 4.19486 4.1971 4.81259 3.43859 4.81259C2.6799 4.81259 2.06353 4.19486 2.06353 3.43762V2.74998H1.49079C0.66776 2.74998 0.000976562 3.42683 0.000976562 4.26206V16.3632C0.000976562 17.1957 0.66776 17.8752 1.49079 17.8752H9.62603V16.4999H1.49079C1.42849 16.4999 1.37603 16.4355 1.37603 16.3632ZM13.7508 11V8.25003H11.001V11H13.7508ZM18.5635 16.4999H16.5009V14.4373C16.5009 14.0575 16.1934 13.75 15.8134 13.75C15.4333 13.75 15.1261 14.0575 15.1261 14.4373V17.1876C15.1261 17.5675 15.4333 17.8751 15.8134 17.8751H18.5635C18.9435 17.8751 19.251 17.5675 19.251 17.1876C19.251 16.8076 18.9435 16.4999 18.5635 16.4999Z"
                fill="#3473AE"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>{{ 'day_full.' + (one.date | localDayOfTheWeek) | translate }}</span>
          <span>{{ one.date | localdate }}</span>
        </div>
        <div class="container_big_without_date">
          <app-event-one-big
            *ngFor="let two of one.events"
            [eventData]="two"
            (moreInfo)="openPopUp($event)"
            class="container_big_without_date_one"
          ></app-event-one-big>
        </div>
      </div>
    </div>
    <div class="footer" *ngIf="showMoreEvents">
      <button class="btn-detail" (click)="moreFilter()">
        {{ 'events.event_big.detail' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="container_pop_up" id="popUp">
  <div class="container_pop_up_close" (click)="closePopUp()"></div>
  <div *ngIf="eventDataPopUp" class="container_pop_up_main">
    <svg
      class="close_container_pop_up"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (click)="closePopUp()"
    >
      <path d="M1 1.00195L10.0012 10.0031" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.3213 1.00195L1.31836 10.0014" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <div class="container_pop_up_main_title">
      <img
        *ngIf="eventDataPopUp.mainImageSmall"
        class="container_pop_up_main_title_item container_pop_up_main_title_image"
        [src]="eventDataPopUp.mainImageSmall.path | img"
      />
      <div class="container_pop_up_main_title_item">
        <h3 class="container_pop_up_main_title_item_title">
          <span *ngFor="let cat of eventDataPopUp.eventCategory">
            <span *ngIf="lang == 'ua'">{{ cat.name.valueUA }}</span>
            <span *ngIf="lang == 'ru'">{{ cat.name.valueRU }}</span>
            <span *ngIf="lang == 'en'">{{ cat.name.valueEN }}</span>
          </span>
        </h3>
        <div class="container_pop_up_main_title_item_content">
          <h4 class="container_pop_up_main_title_item_titleEvent" *ngIf="lang == 'ua'">
            {{ eventDataPopUp.name.valueUA }}
          </h4>
          <h4 class="container_pop_up_main_title_item_titleEvent" *ngIf="lang == 'ru'">
            {{ eventDataPopUp.name.valueRU }}
          </h4>
          <h4 class="container_pop_up_main_title_item_titleEvent" *ngIf="lang == 'en'">
            {{ eventDataPopUp.name.valueEN }}
          </h4>
          <p *ngIf="eventDataPopUp.platformHall && lang == 'ua'" class="container_pop_up_main_title_item_hall">
            {{ eventDataPopUp.platformHall.name.valueUA }}
          </p>
          <p *ngIf="eventDataPopUp.platformHall && lang == 'ru'" class="container_pop_up_main_title_item_hall">
            {{ eventDataPopUp.platformHall.name.valueRU }}
          </p>
          <p *ngIf="eventDataPopUp.platformHall && lang == 'en'" class="container_pop_up_main_title_item_hall">
            {{ eventDataPopUp.platformHall.name.valueEN }}
          </p>
        </div>
      </div>
      <div class="container_pop_up_main_title_item">
        <h3 class="container_pop_up_main_title_item_title">
          {{ 'return.date' | translate }}
        </h3>
        <div class="container_pop_up_main_title_item_content">
          <p class="container_pop_up_main_title_item_contentSecond_up">
            {{ eventDataPopUp.beginDate | localdate }}
          </p>
          <p class="container_pop_up_main_title_item_contentSecond_down">
            {{ eventDataPopUp.beginDate | localtime }}
          </p>
        </div>
      </div>
      <div class="container_pop_up_main_title_item">
        <h3 class="container_pop_up_main_title_item_title">
          {{ 'events.available' | translate }}
        </h3>
        <div class="container_pop_up_main_title_item_content">
          <p class="container_pop_up_main_title_item_contentSecond_down">
            {{ eventDataPopUp.freeSeatsNumber }}
          </p>
        </div>
      </div>
      <div class="container_pop_up_main_title_item">
        <h3 class="container_pop_up_main_title_item_title">
          {{ 'events.price' | translate }}
        </h3>
        <div class="container_pop_up_main_title_item_content">
          <table>
            <tr>
              <td style="border-bottom: 1px solid #c4c4c4; border-right: 1px solid #c4c4c4; color: #a3a3a3; font-size: 0.9vw">
                {{ 'events.event_small.from' | translate }}
              </td>
              <td style="border-bottom: 1px solid #c4c4c4; color: #a3a3a3; font-size: 0.9vw">
                {{ 'events.event_small.to' | translate }}
              </td>
            </tr>
            <tr>
              <td style="border-bottom: 1px solid #c4c4c4; border-right: 1px solid #c4c4c4; color: #5884ac">
                {{ eventDataPopUp.minPrice }}
              </td>
              <td style="border-bottom: 1px solid #c4c4c4; color: #5884ac">
                {{ eventDataPopUp.maxPrice }}
              </td>
            </tr>
            <tr>
              <td style="text-align: center; color: #a3a3a3; font-size: 0.8vw; text-transform: uppercase" colspan="2">
                {{ 'money' | translate }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <h2 class="container_pop_up_main_title_h2">
      {{ 'events.about_event' | translate }}
    </h2>
    <h3 class="container_pop_up_main_title_h3">
      {{ 'events.about_event_short' | translate }}
    </h3>
    <p class="container_pop_up_main_title_item_titleEvent" *ngIf="lang == 'ua'">
      {{ eventDataPopUp.shortDescription.valueUA }}
    </p>
    <p class="container_pop_up_main_title_item_titleEvent" *ngIf="lang == 'ru'">
      {{ eventDataPopUp.shortDescription.valueRU }}
    </p>
    <p class="container_pop_up_main_title_item_titleEvent" *ngIf="lang == 'en'">
      {{ eventDataPopUp.shortDescription.valueEN }}
    </p>
    <div class="container_photo">
      <img *ngFor="let one of eventDataPopUp.images" [src]="one.path | img" alt="" />
    </div>
    <div class="cont-btn-buy">
      <button class="btn-buy" routerLink="/buy/{{ eventDataPopUp.id }}">
        {{ 'events.event_small.tickets' | translate }}
      </button>
      <button class="btn-buy" (click)="navigationToHome(eventDataPopUp.id)">
        {{ 'events.event_small.book' | translate }}
      </button>
    </div>
    <div class="discounts" *ngIf="eventDiscounts.length">
      <div class="discount-list" [ngStyle]="{ 'text-align': isSameEventDiscountExcludes ? 'center' : 'start' }">
        <div class="discount-title">
          Діють такі знижки:
          <br />
        </div>
        <div *ngFor="let discount of eventDiscounts" class="discount">
          {{ discount.everyCounter }}
          <b>{{ discount.everyCounter < 5 ? 'квитки' : 'квитків' }} -{{ discount.discount * 100 }}%</b>
        </div>
      </div>
      <div class="discount-list" *ngIf="!isSameEventDiscountExcludes">
        <div class="discount-title">Не діють на квитки номіналом:</div>
        <div *ngFor="let discount of eventDiscounts" class="discount">
          <span *ngFor="let exludedPrice of discount.exludedPrices; index as i">
            {{ exludedPrice }} грн{{ i + 1 == discount.exludedPrices.length ? '.' : ',' }}
          </span>
          <b *ngIf="discount.exludedPrices.length">( -{{ discount.discount * 100 }}%)</b>
        </div>
      </div>

      <br />
      <div *ngIf="isSameEventDiscountExcludes && eventDiscounts[0]" class="discount-pass">
        Не діють на квитки номіналом:
        <br />
        <span *ngFor="let exludedPrice of eventDiscounts[0].exludedPrices; index as i">
          {{ exludedPrice }} грн{{ i + 1 == eventDiscounts[0].exludedPrices.length ? '.' : ',' }}
        </span>
      </div>
    </div>
  </div>
</div>
