<div class="container">
  <!--white-->
  <!-- <div class="white_container">
    <div class="filter_container_name">
    </div>
  </div> -->

  <div class="title_container">
    <h2 class="main-h2">{{ 'accountant.title' | translate }}</h2>

    <div class="reports-nav">
      <a routerLink="/accountant/events-statistics" class="nav-link" routerLinkActive="nav-link-active">
        {{ 'accountant.nav_inks.event_statistics' | translate }}
      </a>
      <a routerLink="/accountant/report-cashier" class="nav-link" routerLinkActive="nav-link-active">
        {{ 'accountant.nav_inks.report_cashier' | translate }}
      </a>
      <a routerLink="/accountant/sails-by-month" class="nav-link" routerLinkActive="nav-link-active">
        {{ 'accountant.nav_inks.sails_by_month' | translate }}
      </a>
    </div>
  </div>

  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
