<div class="container">
  <div class="filter_container">
    <div class="filter_container_info"></div>
  </div>
  <div class="title_container" *ngIf="currentEvent.name">
    <div>
      <a class="main-h2" routerLink="/" href="">
        <svg style="width: 17px !important; height: 17px !important" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.0444 5.72693L16.0709 5.73273H4.69532L8.2714 2.14877C8.44652 1.9738 8.54257 1.73676 8.54257 1.48798C8.54257 1.2392 8.44652 1.00383 8.2714 0.828439L7.71496 0.271724C7.53999 0.0967479 7.30683 0 7.05818 0C6.8094 0 6.5761 0.096057 6.40113 0.271033L0.271036 6.40057C0.0953696 6.57624 -0.000687354 6.81023 3.70287e-06 7.05915C-0.000687354 7.30945 0.0953696 7.54358 0.271036 7.71897L6.40113 13.8491C6.5761 14.0239 6.80926 14.1201 7.05818 14.1201C7.30683 14.1201 7.53999 14.0238 7.71496 13.8491L8.2714 13.2923C8.44652 13.1176 8.54257 12.8843 8.54257 12.6356C8.54257 12.3869 8.44652 12.1659 8.2714 11.9911L4.65496 8.38708H16.0571C16.5695 8.38708 17 7.9455 17 7.43342V6.64603C17 6.13396 16.5568 5.72693 16.0444 5.72693Z"
            fill="#3473AE"
          />
        </svg>
        {{ 'events.event_title' | translate }}
      </a>
      <span class="title_container_span" *ngIf="lang == 'ua'">{{ currentEvent.name.valueUA }}</span>
      <span class="title_container_span" *ngIf="lang == 'en'">{{ currentEvent.name.valueEN }}</span>
      <span class="title_container_span" *ngIf="lang == 'ru'">{{ currentEvent.name.valueRU }}</span>
    </div>
    <div class="info_main" *ngIf="currentEvent.platformHall">
      <p class="info_main_text" *ngIf="lang == 'ua'">{{ currentEvent.platformHall.city.dictionary.valueUA }}</p>
      <p class="info_main_text" *ngIf="lang == 'en'">{{ currentEvent.platformHall.city.dictionary.valueEN }}</p>
      <p class="info_main_text" *ngIf="lang == 'ru'">{{ currentEvent.platformHall.city.dictionary.valueRU }}</p>
      <div class="info_container_date">
        <p class="info_container_date_up">{{ currentEvent.dataBegin | localdate }}</p>
        <p class="info_container_date_down">{{ currentEvent.dataBegin | localtime }}</p>
      </div>
      <p class="info_main_text" *ngIf="lang == 'ua'">{{ currentEvent.platformHall.name.valueUA }}</p>
      <p class="info_main_text" *ngIf="lang == 'en'">{{ currentEvent.platformHall.name.valueEN }}</p>
      <p class="info_main_text" *ngIf="lang == 'ru'">{{ currentEvent.platformHall.name.valueRU }}</p>
      <p class="info_container_date_up">
        <span *ngFor="let one of currentEvent.eventCategory">
          <span *ngIf="lang == 'ua'">{{ one.name.valueUA }}</span>
          <span *ngIf="lang == 'en'">{{ one.name.valueRU }}</span>
          <span *ngIf="lang == 'ru'">{{ one.name.valueEN }}</span>
        </span>
      </p>
    </div>
  </div>
  <div class="content-wrapper">
    <div class="booking-action">
      <a class="add-action" routerLink="/invitations/{{ eventId }}/new">Створити запрошення</a>
    </div>
    <div class="booking-table">
      <nz-table #invitesTable [nzPageSize]="size" [nzData]="invites">
        <thead>
          <tr>
            <th>№</th>
            <th>Дата створення</th>
            <th>Ім'я</th>
            <th>Прізвище</th>
            <th>Email</th>
            <th>Одержувач</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of invitesTable.data">
            <td>{{ data.order }}</td>
            <td>{{ data.created | date : 'dd.MM.yyyy HH:mm' }}</td>
            <td>{{ data.user?.firstName }}</td>
            <td>{{ data.user?.lastName }}</td>
            <td>{{ data.user?.mail }}</td>
            <td>{{ data.comment }}</td>
            <td>
              <div style="display: flex; gap: 5px; align-items: center">
                <a (click)="onOpenPrintDialog(data.order)"><i nz-icon nzType="printer" nzTheme="outline"></i></a>
                <i class="delete-icon" (click)="deleteInvitation(data.id)" nz-icon nzType="delete" nzTheme="outline"></i>
                <a (click)="printKg(data.id)">
                  <i nz-icon nzType="file-excel" nzTheme="outline"></i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isPrintModalVisible"
  nzTitle="перелік запрошень:"
  [nzMaskClosable]="false"
  (nzOnCancel)="isPrintModalVisible = false"
  [nzWidth]="700"
>
  <ng-template nzModalContent>
    <app-tickets-modal
      [tickets]="tickets"
      [orderId]="currentOrderId"
      actionMode="invite"
      [simplePreview]="false"
      [eventId]="null"
    ></app-tickets-modal>
  </ng-template>
  <div *nzModalFooter class="actions-modal-footer"></div>
</nz-modal>
