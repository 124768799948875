<div class="container">
  <!--white-->
  <div class="white_container">
    <div class="filter_container_name"></div>
    <div class="filter_container_form">
      <form [formGroup]="filterForm">
        <label class="label_status_date">
          <input type="date" class="status_date" name="dateFrom" formControlName="dateFrom" (change)="filter()" />
        </label>
        <label class="label_status_date">
          <input type="date" class="status_date" name="dateTo" formControlName="dateTo" (change)="filter()" />
        </label>
        <label class="label-type-pay">
          <select class="type-pay" name="type_of_payment" formControlName="type_of_payment" (change)="filter()">
            <option [value]="0">{{ 'history.type_pay' | translate }}</option>
            <option value="CASH">{{ 'history.typeOfPaymentCash' | translate }}</option>
            <option value="CARD">{{ 'history.typeOfPaymentCard' | translate }}</option>
            <option value="NONE">{{ 'history.typeOfPaymentNone' | translate }}</option>
          </select>
        </label>
        <label class="label-type-pay" style="margin-top: 5px">
          <select class="type-pay" name="ownEventsOnly" formControlName="ownEventsOnly" (change)="filter()">
            <option [value]="0">{{ 'history.all_events' | translate }}</option>
            <option [value]="true">{{ 'history.own' | translate }}</option>
            <option [value]="false">{{ 'history.other' | translate }}</option>
          </select>
        </label>
        <label class="label-type-pay" *ngIf="ownEvents === 'false'" style="margin-top: 5px">
          <select class="type-pay" name="ownEventsOnly" formControlName="organizators" (change)="filter()">
            <option [value]="0">{{ 'history.all_organizers' | translate }}</option>
            <option *ngFor="let organizer of organizers$ | async" [value]="organizer.id">
              {{ organizer?.firstName }} {{ organizer?.lastName }}
            </option>
          </select>
        </label>
        <label class="label-hall" style="margin-top: 5px">
          <select class="hall" name="platform_hall" formControlName="platform_hall" (change)="filter()">
            <option [value]="0">{{ 'history.hall' | translate }}</option>
            <option *ngFor="let one of platforms" value="{{ one.id }}">
              <span *ngIf="lang == 'ua'">{{ one.name.valueUA }}</span>
              <span *ngIf="lang == 'ru'">{{ one.name.valueRU }}</span>
              <span *ngIf="lang == 'en'">{{ one.name.valueEN }}</span>
            </option>
          </select>
        </label>
        <!-- TODO: implement after BE integration -->
        <!-- <label class="label-hall"
          style=" margin-top: 5px;">
          <select class="hall"
            name="platform_hall"
            formControlName="event_id"
            (change)="filter()">
            <option [value]="0">{{'history.all_events' | translate}}</option>
            <option *ngFor="let one of platforms"
              value="{{one.id}}">
              <span *ngIf="lang == 'ua'">{{one.name.valueUA}}</span>
              <span *ngIf="lang == 'ru'">{{one.name.valueRU}}</span>
              <span *ngIf="lang == 'en'">{{one.name.valueEN}}</span>
            </option>
          </select>
        </label> -->
        <label style="margin-top: 5px" class="label_btn_reset" (click)="resetFilter()">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.8782 5.42088L14.3242 2.73345C14.1549 2.4232 13.7894 2.261 13.4062 2.40264L11.6502 3.10814C11.3166 2.86802 10.966 2.66402 10.6033 2.50039L10.3362 0.6375C10.2909 0.274125 9.97572 0 9.60311 0H6.48643C6.11386 0 5.79863 0.274125 5.75401 0.631125L5.48626 2.4997C5.13493 2.65907 4.78995 2.86025 4.44145 3.10814L2.68059 2.40055C2.34202 2.27023 1.93827 2.41686 1.76966 2.72498L0.212737 5.41732C0.0257369 5.73464 0.102934 6.1462 0.393362 6.3757L1.88156 7.53877C1.85818 7.76332 1.84686 7.95952 1.84686 8.14582C1.84686 8.33212 1.85818 8.52832 1.88086 8.75288L0.391934 9.91668C0.107881 10.1412 0.0299869 10.5393 0.211309 10.8708L1.76538 13.5589C1.93468 13.8685 2.30088 14.0307 2.68408 13.889L4.44003 13.1835C4.77365 13.4236 5.12428 13.6276 5.48695 13.7912L5.75401 15.6534C5.79863 16.0175 6.11383 16.2916 6.48643 16.2916H8.92736C9.05981 16.2916 9.18024 16.218 9.24117 16.1011C9.30209 15.9842 9.29286 15.8433 9.21779 15.7349C8.67591 14.9586 8.38904 14.0483 8.38904 13.1041C8.38904 12.6636 8.45349 12.2223 8.58099 11.7923C8.61356 11.6818 8.59091 11.5628 8.51936 11.4721C8.44781 11.3822 8.33804 11.334 8.22256 11.3376C8.18431 11.3397 8.14676 11.3446 8.05043 11.3567C6.28881 11.3567 4.85586 9.92375 4.85586 8.16213C4.85586 6.4005 6.28881 4.96682 8.05043 4.96682C9.81206 4.96682 11.245 6.39977 11.2386 8.2202C11.2337 8.25775 11.2287 8.29527 11.2266 8.33352C11.2209 8.44897 11.2712 8.55947 11.3612 8.63032C11.4518 8.70187 11.5715 8.72313 11.6813 8.69195C12.3705 8.48652 13.0498 8.43764 13.8191 8.5772C13.9133 8.5942 14.0167 8.5517 14.096 8.49289C14.1747 8.43409 14.2271 8.32502 14.2377 8.22727C14.2391 8.21452 14.2427 8.15714 14.2427 8.14439C14.2427 7.92409 14.2271 7.71657 14.2087 7.53734L15.6969 6.375C15.981 6.15118 16.0589 5.75307 15.8782 5.42088Z"
              fill="#72002D"
            />
          </svg>
          <span style="color: #fff" class="reset-text">{{ 'events.reset_filter' | translate }}</span>
        </label>
      </form>
    </div>
  </div>
  <!--header-->
  <div class="index_container_declaration" id="title">
    <h2 class="main-h2">{{ 'history.history' | translate }}</h2>
    <div class="container-buttons">
      <a routerLink="/history" href="">
        <button class="green-btn text-style">{{ 'history.paid' | translate }}</button>
      </a>
      <a routerLink="booked" href="">
        <button class="green-btn-no-active text-style">{{ 'history.reserv' | translate }}</button>
      </a>
      <a routerLink="return-tickets" href="">
        <button class="green-btn-no-active text-style">{{ 'history.return' | translate }}</button>
      </a>
    </div>
    <div class="btn-save">
      <button class="text-style pointer" (click)="downloadExcel()">
        <label class="image-save">
          <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.8625 5.43889V22H2.17871V0H13.4216L18.8625 5.43889Z" fill="#549C39" />
            <path d="M2.86328 0.671875V21.2052H18.2605V6.13113H12.6162V0.671875H2.86328Z" fill="white" />
            <path d="M21 10.9395H0V17.7839H21V10.9395Z" fill="#549C39" />
            <path
              d="M4.61121 14.5367H2.78098V16.203H4.82287V16.7042H2.1709V12.0801H4.71497V12.5812H2.78098V14.0438H4.61121V14.5367Z"
              fill="white"
            />
            <path
              d="M8.19322 16.7042L7.59144 15.6816C7.34658 15.2904 7.19302 15.0379 7.04777 14.769H7.03532C6.90251 15.0379 6.7697 15.2823 6.52484 15.6897L5.95627 16.7042H5.25488L6.695 14.3616L5.31299 12.0801H6.01852L6.64105 13.1638C6.81536 13.4653 6.94816 13.6975 7.07267 13.946H7.09342C7.22622 13.673 7.34658 13.4571 7.52089 13.1638L8.16417 12.0801H8.86555L7.42958 14.329L8.89875 16.7042H8.19322Z"
              fill="white"
            />
            <path
              d="M12.6954 16.5529C12.4713 16.6629 12.0231 16.7729 11.4503 16.7729C10.1223 16.7729 9.12207 15.9499 9.12207 14.4303C9.12207 12.9799 10.1223 11.998 11.5831 11.998C12.1725 11.998 12.5418 12.1203 12.7037 12.2058L12.5584 12.6947C12.326 12.5847 11.9982 12.5032 11.608 12.5032C10.5041 12.5032 9.7695 13.1958 9.7695 14.4099C9.7695 15.5425 10.4335 16.2718 11.579 16.2718C11.9484 16.2718 12.326 16.1944 12.5709 16.0803L12.6954 16.5529Z"
              fill="white"
            />
            <path
              d="M15.9003 14.5367H14.07V16.203H16.1119V16.7042H13.46V12.0801H16.004V12.5812H14.0659V14.0438H15.8961V14.5367H15.9003Z"
              fill="white"
            />
            <path d="M16.9004 12.0801H17.5105V16.2071H19.5233V16.7042H16.9004V12.0801Z" fill="white" />
          </svg>
        </label>
        {{ 'history.save' | translate }}
      </button>
    </div>
  </div>
  <!--dste-->
  <div class="statistics-container">
    <p>
      {{
        !(filterForm.get('dateTo').value || filterForm.get('dateFrom').value)
          ? 'Результат продажів станом на'
          : ('history.result_date-label' | translate)
      }}
      <span class="statistics-data">{{ salesPeriod }}</span>
    </p>
    <div class="sales-data">
      <p>
        {{ 'history.result_qty' | translate }}
        <span class="statistics-data">{{ satesStatistics.quantity }}</span>
        шт
      </p>
      <p>
        {{ 'history.result_amount' | translate }}
        <span class="statistics-data">{{ satesStatistics.amount }}</span>
        грн
      </p>
    </div>
  </div>

  <div *ngFor="let one of bins" style="margin-bottom: 3vh">
    <div class="container-date">
      <div class="center">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path
              d="M2.75095 3.43758V0.687506C2.75095 0.307458 3.0584 0 3.43854 0C3.8185 0 4.12596 0.307458 4.12596 0.687506V3.43758C4.12596 3.81753 3.8185 4.12499 3.43854 4.12499C3.0584 4.12504 2.75095 3.81753 2.75095 3.43758ZM14.4386 4.12504C14.8184 4.12504 15.1261 3.81758 15.1261 3.43762V0.687506C15.1261 0.307458 14.8184 0 14.4386 0C14.0585 0 13.7508 0.307458 13.7508 0.687506V3.43758C13.7508 3.81753 14.0585 4.12504 14.4386 4.12504ZM22.0009 16.4999C22.0009 19.5375 19.5383 22.0001 16.5009 22.0001C13.4637 22.0001 11.001 19.5375 11.001 16.4999C11.001 13.4627 13.4636 11 16.5009 11C19.5383 11 22.0009 13.4626 22.0009 16.4999ZM20.6261 16.4999C20.6261 14.2255 18.7757 12.3749 16.5009 12.3749C14.2264 12.3749 12.376 14.2255 12.376 16.4999C12.376 18.7747 14.2264 20.625 16.5009 20.625C18.7757 20.625 20.6261 18.7747 20.6261 16.4999ZM5.50101 8.25003H2.75095V11H5.50101V8.25003ZM2.75095 15.125H5.50101V12.3749H2.75095V15.125ZM6.87593 11H9.62603V8.25003H6.87593V11ZM6.87593 15.125H9.62603V12.3749H6.87593V15.125ZM1.37603 16.3632V6.87497H16.5009V9.62504H17.876V4.26206C17.876 3.42683 17.2098 2.74998 16.3869 2.74998H15.8134V3.43762C15.8134 4.19486 15.197 4.81259 14.4386 4.81259C13.68 4.81259 13.0636 4.19486 13.0636 3.43762V2.74998H4.81342V3.43762C4.81342 4.19486 4.1971 4.81259 3.43859 4.81259C2.6799 4.81259 2.06353 4.19486 2.06353 3.43762V2.74998H1.49079C0.66776 2.74998 0.000976562 3.42683 0.000976562 4.26206V16.3632C0.000976562 17.1957 0.66776 17.8752 1.49079 17.8752H9.62603V16.4999H1.49079C1.42849 16.4999 1.37603 16.4355 1.37603 16.3632ZM13.7508 11V8.25003H11.001V11H13.7508ZM18.5635 16.4999H16.5009V14.4373C16.5009 14.0575 16.1934 13.75 15.8134 13.75C15.4333 13.75 15.1261 14.0575 15.1261 14.4373V17.1876C15.1261 17.5675 15.4333 17.8751 15.8134 17.8751H18.5635C18.9435 17.8751 19.251 17.5675 19.251 17.1876C19.251 16.8076 18.9435 16.4999 18.5635 16.4999Z"
              fill="#3473AE"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div class="center-text">
          <p class="text-style">{{ 'day_full.' + (one.date | localDayOfTheWeek) | translate }}</p>
          <p class="text-style">{{ one.date | localdate }}</p>
        </div>
      </div>
    </div>
    <!--tickets-->
    <div class="container-event" *ngFor="let two of one.bins; let i = index">
      <div class="about-event">
        <div class="open-close-details">
          <svg
            width="11"
            height="20"
            viewBox="0 0 11 20"
            fill="none"
            class="pointer"
            xmlns="http://www.w3.org/2000/svg"
            id="open-close_{{ two.id }}"
            (click)="openCloseDetails('details_' + two.id, 'open-close_' + two.id, two)"
          >
            <path d="M10.4805 9.74962L0.980469 19.2441L0.980468 0.255098L10.4805 9.74962Z" fill="#C4C4C4" />
          </svg>
        </div>
        <div class="number-event text-style">
          <p>{{ i + 1 }}</p>
        </div>
        <div class="what-event">
          <div class="centers">
            <div class="event-headers text-style">
              <p class="text-style headers">{{ 'history.event' | translate }}</p>
              <p class="text-style headers where" *ngIf="lang == 'ua'">
                {{ two.event.platformHall.city.dictionary.valueUA }}
              </p>
              <p class="text-style headers where" *ngIf="lang == 'ru'">
                {{ two.event.platformHall.city.dictionary.valueRU }}
              </p>
              <p class="text-style headers where" *ngIf="lang == 'en'">
                {{ two.event.platformHall.city.dictionary.valueEN }}
              </p>
            </div>
            <div class="name-event text-style">
              <p *ngIf="lang == 'ua'">{{ two.event.name.valueUA }}</p>
              <p *ngIf="lang == 'ru'">{{ two.event.name.valueRU }}</p>
              <p *ngIf="lang == 'en'">{{ two.event.name.valueEN }}</p>
            </div>
          </div>
        </div>
        <div class="order">
          <div class="centers">
            <p class="text-style headers">{{ 'history.order' | translate }}</p>
            <div class="order-text">
              <p class="text-style">№{{ two.order }}</p>
            </div>
          </div>
        </div>
        <div class="kilk">
          <div class="centers">
            <p class="text-style headers">{{ 'history.kilk' | translate }}</p>
            <div class="order-text">
              <p class="text-style">{{ two.countTicket }}</p>
            </div>
          </div>
        </div>
        <div class="time">
          <div class="centers">
            <p class="text-style headers">{{ 'history.time' | translate }}</p>
            <div class="order-text">
              <p class="text-style">{{ two.created | localtime }}</p>
            </div>
          </div>
        </div>
        <div class="pay">
          <div class="centers">
            <p class="text-style headers">{{ 'history.pay' | translate }}</p>
            <div class="order-text">
              <nz-select (ngModelChange)="changePaymentType($event, two)" [ngModel]="two.typeOfPayment">
                <nz-option nzValue="CARD" nzLabel="{{ 'history.typeOfPaymentCard' | translate }}"></nz-option>
                <nz-option nzValue="CASH" nzLabel="{{ 'history.typeOfPaymentCash' | translate }}"></nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="cashier">
          <div class="centers">
            <p class="text-style headers">{{ 'history.cashier' | translate }}</p>
            <div class="order-text">
              <p class="text-style" *ngIf="two.sender">{{ two.sender.firstName + ' ' + two.sender.lastName }}</p>
            </div>
          </div>
        </div>
        <button class="btn-suma pointer" (click)="openClosePopUp('popUp', two.order, two.event)">
          <div class="centers">
            <p class="text-style headers-suma">{{ 'history.suma' | translate }}</p>
            <div class="suma-text">
              <p class="text-style">{{ two.price }} {{ 'money' | translate }}</p>
            </div>
          </div>
        </button>
      </div>
      <div class="details-event" id="details_{{ two.id }}">
        <div class="open-close-details"></div>
        <div class="details-headers">
          <div class="header-hall">
            <p class="text-style headers">{{ 'history.hall' | translate }}</p>
          </div>
          <div class="header-date">
            <p class="text-style headers">{{ 'history.date' | translate }}</p>
          </div>
        </div>
        <div class="details-text">
          <div class="hall-text">
            <p class="text-style" *ngIf="lang == 'ua'">{{ two.event.platformHall.name.valueUA }}</p>
            <p class="text-style" *ngIf="lang == 'ru'">{{ two.event.platformHall.name.valueRU }}</p>
            <p class="text-style" *ngIf="lang == 'en'">{{ two.event.platformHall.name.valueEN }}</p>
          </div>
          <div class="date-text">
            <p class="text-style">{{ two.event.beginDate | localdate }}, {{ two.event.beginDate | localtime }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="bins" class="container_pageable">
    <app-pageable [page]="pageable" (countOnPage)="changeCount($event)" (openPage)="open($event)" [range]="4"></app-pageable>
  </div>

  <div class="container-popUp" id="popUp">
    <div class="dark" *ngIf="popUpMain">
      <div class="tick-pop">
        <!--close-->
        <div class="close">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="openClosePopUp('popUp')">
            <path d="M2 2.00195L13.9977 13.9996" stroke="#4D4A4A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14 2.00195L2.00034 13.9969" stroke="#4D4A4A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="cont-ticks">
          <div class="print-wrapper">
            <div class="print-title-wrapper">
              <h5 class="print-title">Виберіть квитки:</h5>
              <label for="printAll">
                Вибрати всі
                <input
                  [checked]="popUpMain.tickets.length === ticketsToPrint.length"
                  (change)="setAllToPrint($event)"
                  name="printAll"
                  type="checkbox"
                />
              </label>
            </div>
            <div class="print-actions" *ngIf="ticketsToPrint.length">
              <button class="print-button" (click)="printTickets('a4')">Друк А4</button>
              <button class="print-button" (click)="printTickets('thermo')">Друк Термо</button>
              <button class="print-button" (click)="printTickets('regular')">Друк Звичайний</button>
            </div>
          </div>
          <div class="one-ticket" *ngFor="let ticket of popUpMain.tickets">
            <div class="tick-header">
              <input
                type="checkbox"
                class="ticket-check"
                [checked]="ticketsToPrint.includes(ticket.id)"
                (change)="setToPrint($event, ticket.id)"
              />
              <p class="text-style" valueByLang [dictionary]="popUpMain.event.name"></p>
            </div>
            <div class="about-event-pop" style="border: 0 !important">
              <div class="section">
                <p class="text-style about-header">{{ 'collection.type' | translate }}</p>
                <div class="about-text">
                  <p class="text-style">{{ ticket.sector }}</p>
                </div>
              </div>
              <div class="section" *ngIf="ticket.seatPosition">
                <p class="text-style about-header">{{ 'collection.row' | translate }}</p>
                <div class="about-text">
                  <p class="text-style">{{ ticket.row }}</p>
                </div>
              </div>
              <div class="section" *ngIf="ticket.seatPosition">
                <p class="text-style about-header">{{ 'collection.place' | translate }}</p>
                <div class="about-text">
                  <p class="text-style">{{ ticket.seatPosition }}</p>
                </div>
              </div>
              <div class="section">
                <p class="text-style about-header">{{ 'collection.price' | translate }}</p>
                <div class="about-text">
                  <p class="text-style">{{ ticket.finalPrice }}{{ 'money' | translate }}</p>
                </div>
              </div>
              <div class="section">
                <p class="text-style about-header">{{ 'collection.date' | translate }}</p>
                <div class="about-text">
                  <p class="text-style">{{ popUpMain.event.beginDate | localdate }} , {{ popUpMain.event.beginDate | localtime }}</p>
                </div>
              </div>
              <div class="section-row">
                <p class="text-style about-header">{{ 'collection.city' | translate }}</p>
                <div class="about-text">
                  <p class="text-style" valueByLang [dictionary]="popUpMain.event.platformHall.city.dictionary"></p>
                </div>
              </div>
            </div>
            <!--<div class="price-tick">-->
            <!--<p class="text-style">{{one.finalPrice}}</p>-->
            <!--<p class="text-style">{{'money'|translate}}</p>-->
            <!--</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
