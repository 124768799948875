<div class="container">
  <div class="white_container">
    <div class="filter_container_name">
      <p></p>
    </div>
  </div>

  <div class="title_container">
    <h2 class="main-h2">{{'agents.title'|translate}}</h2>

    <div class="content">
      <app-agent-add (addAgent)="onAddAgent($event)"></app-agent-add>
    </div>
  </div>

  <nz-spin nzTip="Завантажую..." [nzSpinning]="!vendors || isLoading" nzSize="large">
    <app-agents-list
      [selectedVendor]="selectedVendor"
      [vendors]="vendors"
      [pagination]="pagination"
      (orderRemove)="onOrderRemove($event)"
      (eventModal)="onActionsModalShow($event)"
      (buyOrder)="onOrderBuy($event)"
      (orderEdit)="onOrderEdit($event)"
      (editVendor)="onVendorEdit($event)"
      (onExpandVendor)="getVendorBins($event)"
      (orderBuyDocumentPrint)="onOrderBuyDocumentPrint($event)"
      (onPageChange)="pageExpandedChange($event)"
      (onPageChangeAllVendors)="getVendors($event)"
    ></app-agents-list>
  </nz-spin>
</div>

<nz-modal
  [(nzVisible)]="isActionsModalVisible"
  [nzTitle]="'перелік квитків на подію: ' + modalEventName"
  [nzMaskClosable]="false"
  (nzOnCancel)="onTicketsModalClose()"
  [nzWidth]="modalWidth"
>
  <ng-template nzModalContent>
    <app-tickets-modal
      [isVendor]="true"
      [tickets]="tickets"
      [orderId]="currentBinId"
      [actionMode]="modalAction"
      [simplePreview]="simpleTicketsTable"
      [eventId]="currentEventId"
      (onActionComplete)="getVendors()"
    ></app-tickets-modal>
  </ng-template>
  <div *nzModalFooter class="actions-modal-footer"></div>
</nz-modal>
