<div class="container">
  <!--white-->
  <div class="white_container">
    <div class="filter_container_name"></div>
    <div class="filter_container_form">
      <form #mainForm>
        <label class="label-cashier">
          <select *ngIf="cashiers" class="cashiers pointer" name="paymasters" (change)="loadCollections()">
            <option value="null">{{ 'history.cashier' | translate }}</option>
            <option *ngFor="let one of cashiers.content" value="{{ one.id }}">{{ one.firstName }} {{ one.lastName }}</option>
          </select>
        </label>
        <label class="label_status_date">
          <select class="status_date pointer" [(ngModel)]="status" [ngModelOptions]="{ standalone: true }" (change)="loadCollections()">
            <option value="undefined">всі</option>
            <option value="CONFIRM">перевірено</option>
            <option value="CREATE">не перевірений</option>
          </select>
        </label>
        <label class="label_status_date">
          <input class="status_date" type="date" name="dateFrom" (change)="loadCollections()" />
        </label>
        <label class="label_status_date">
          <input class="status_date" type="date" name="dateTo" (change)="loadCollections()" />
        </label>
        <svg
          (click)="resetFilter()"
          class="iconClear"
          fill="none"
          height="15"
          viewBox="0 0 15 15"
          width="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15 1.5L13.5 0L7.5 6L1.5 0L0 1.5L6 7.5L0 13.5L1.5 15L7.5 9L13.5 15L15 13.5L9 7.5L15 1.5Z" fill="#C4C4C4"></path>
        </svg>
      </form>
    </div>
  </div>
  <!--header-->
  <div class="index_container_declaration">
    <h2 class="main-h2">{{ 'collection.collection' | translate }}</h2>
    <div style="display: flex">
      <div class="container-switch">
        <button>
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [ngClass]="{ actives: step == 0 }"
            (click)="step = 0"
          >
            <path
              d="M0.425926 3.40654H22.5741C22.8296 3.40654 23 3.23617 23 2.98061C23 2.72506 22.8296 2.55469 22.5741 2.55469H0.425926C0.17037 2.55469 0 2.72506 0 2.98061C0 3.23617 0.17037 3.40654 0.425926 3.40654Z"
              fill="#C4C4C4"
            />
            <path
              d="M0.425926 12.3519H22.5741C22.8296 12.3519 23 12.1815 23 11.9259C23 11.6704 22.8296 11.5 22.5741 11.5H0.425926C0.17037 11.5 0 11.6704 0 11.9259C0 12.1815 0.17037 12.3519 0.425926 12.3519Z"
              fill="#C4C4C4"
            />
            <path
              d="M0.425926 21.2972H22.5741C22.8296 21.2972 23 21.1268 23 20.8712C23 20.6157 22.8296 20.4453 22.5741 20.4453H0.425926C0.17037 20.4453 0 20.6157 0 20.8712C0 21.1268 0.17037 21.2972 0.425926 21.2972Z"
              fill="#C4C4C4"
            />
            <path
              d="M7.83736 6.13333H2.17254C1.74661 6.13333 1.36328 5.79259 1.36328 5.32407V0.809259C1.36328 0.383333 1.70402 0 2.17254 0H7.83736C8.26328 0 8.64661 0.340741 8.64661 0.809259V5.36667C8.64661 5.79259 8.26328 6.13333 7.83736 6.13333Z"
              fill="#C4C4C4"
            />
            <path
              d="M7.83736 14.9927H2.17254C1.74661 14.9927 1.36328 14.652 1.36328 14.1834V9.66863C1.36328 9.24271 1.70402 8.85937 2.17254 8.85937H7.83736C8.26328 8.85937 8.64661 9.20012 8.64661 9.66863V14.226C8.64661 14.652 8.26328 14.9927 7.83736 14.9927Z"
              fill="#C4C4C4"
            />
            <path
              d="M7.83736 23.8521H2.17254C1.74661 23.8521 1.36328 23.5113 1.36328 23.0428V18.528C1.36328 18.1021 1.70402 17.7187 2.17254 17.7187H7.83736C8.26328 17.7187 8.64661 18.0595 8.64661 18.528V23.0854C8.64661 23.5113 8.26328 23.8521 7.83736 23.8521Z"
              fill="#C4C4C4"
            />
          </svg>
        </button>
        <button [ngClass]="{ actives: step == 1 }" (click)="step = 1">
          <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.425926 23.9991H22.5741C22.8296 23.9991 23 23.8071 23 23.5191C23 23.2311 22.8296 23.0391 22.5741 23.0391H0.425926C0.17037 23.0391 0 23.2311 0 23.5191C0 23.8071 0.17037 23.9991 0.425926 23.9991Z"
              fill="#C4C4C4"
            />
            <path
              d="M17.5058 14.688H5.49472C4.55768 14.688 3.79102 13.824 3.79102 12.768V1.92C3.79102 0.864 4.55768 0 5.49472 0H17.5058C18.4429 0 19.2095 0.864 19.2095 1.92V12.768C19.2095 13.824 18.4429 14.688 17.5058 14.688Z"
              fill="#C4C4C4"
            />
          </svg>
        </button>
      </div>
      <div class="btn-save pointer" (click)="downLoad()">
        <button class="text-style pointer">
          <label class="image-save">
            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.8625 5.43889V22H2.17871V0H13.4216L18.8625 5.43889Z" fill="#549C39" />
              <path d="M2.86328 0.671875V21.2052H18.2605V6.13113H12.6162V0.671875H2.86328Z" fill="white" />
              <path d="M21 10.9395H0V17.7839H21V10.9395Z" fill="#549C39" />
              <path
                d="M4.61121 14.5367H2.78098V16.203H4.82287V16.7042H2.1709V12.0801H4.71497V12.5812H2.78098V14.0438H4.61121V14.5367Z"
                fill="white"
              />
              <path
                d="M8.19322 16.7042L7.59144 15.6816C7.34658 15.2904 7.19302 15.0379 7.04777 14.769H7.03532C6.90251 15.0379 6.7697 15.2823 6.52484 15.6897L5.95627 16.7042H5.25488L6.695 14.3616L5.31299 12.0801H6.01852L6.64105 13.1638C6.81536 13.4653 6.94816 13.6975 7.07267 13.946H7.09342C7.22622 13.673 7.34658 13.4571 7.52089 13.1638L8.16417 12.0801H8.86555L7.42958 14.329L8.89875 16.7042H8.19322Z"
                fill="white"
              />
              <path
                d="M12.6954 16.5529C12.4713 16.6629 12.0231 16.7729 11.4503 16.7729C10.1223 16.7729 9.12207 15.9499 9.12207 14.4303C9.12207 12.9799 10.1223 11.998 11.5831 11.998C12.1725 11.998 12.5418 12.1203 12.7037 12.2058L12.5584 12.6947C12.326 12.5847 11.9982 12.5032 11.608 12.5032C10.5041 12.5032 9.7695 13.1958 9.7695 14.4099C9.7695 15.5425 10.4335 16.2718 11.579 16.2718C11.9484 16.2718 12.326 16.1944 12.5709 16.0803L12.6954 16.5529Z"
                fill="white"
              />
              <path
                d="M15.9003 14.5367H14.07V16.203H16.1119V16.7042H13.46V12.0801H16.004V12.5812H14.0659V14.0438H15.8961V14.5367H15.9003Z"
                fill="white"
              />
              <path d="M16.9004 12.0801H17.5105V16.2071H19.5233V16.7042H16.9004V12.0801Z" fill="white" />
            </svg>
          </label>
          {{ 'history.save' | translate }}
        </button>
      </div>
    </div>
  </div>
  <!--container with tickets-->
  <div class="containers">
    <div class="container-tickets" *ngIf="collections && step == 0">
      <div class="one-tick" *ngFor="let one of collections.content">
        <!--number ticket-->
        <div class="number">
          <p class="text-style">№</p>
          <p class="text-style">{{ one.id }}</p>
        </div>
        <!--header-->
        <div class="header-tick">
          <p class="text-style">{{ one.user.firstName }} {{ one.user.lastName }}</p>
        </div>
        <!--kilk and suma-->
        <div class="sold-tick">
          <div class="kilk">
            <p class="text-style kilk-suma-text">{{ 'collection.kilk_sold_tick' | translate }}</p>
            <p class="text-style bold-text">{{ one.countTicket }} шт</p>
          </div>
          <div class="kilk">
            <p class="text-style kilk-suma-text">{{ 'collection.suma_sold_tick' | translate }}</p>
            <p class="text-style bold-text">{{ one.price }} {{ 'money' | translate }}</p>
          </div>
          <div class="button-detial" (click)="openClosePopUp('popUp', true, 1, one.id)">
            <button class="text-style">{{ 'collection.detail' | translate }}</button>
          </div>
        </div>
        <div class="sold-tick">
          <div class="kilk">
            <p class="text-style kilk-suma-text">{{ 'collection.suma_ret_tick' | translate }}</p>
            <p class="text-style bold-text">{{ one.revertTicketsCount }} шт</p>
          </div>
          <div class="kilk">
            <p class="text-style kilk-suma-text">{{ 'collection.suma_sold_tick' | translate }}</p>
            <p class="text-style bold-text">{{ one.priceMinus }} {{ 'money' | translate }}</p>
          </div>
          <div class="button-detial" (click)="openClosePopUp('popUp', true, 0, one.id)">
            <button class="text-style">{{ 'collection.detail' | translate }}</button>
          </div>
        </div>
        <!--container with suma-->
        <div class="suma-container">
          <div class="cash">
            <p class="text-style cash-header">{{ 'collection.cash' | translate }}</p>
            <p class="text-style bold-cash">{{ one.priceCash }} {{ 'money' | translate }}</p>
          </div>
          <div class="cash">
            <p class="text-style cash-header">{{ 'collection.terminal' | translate }}</p>
            <p class="text-style bold-cash">{{ one.priceCard }} {{ 'money' | translate }}</p>
          </div>
          <div class="cash">
            <p class="text-style all-header">{{ 'collection.all' | translate }}</p>
            <p class="text-style bold-all">{{ one.priceBoxOffice + one.price }} {{ 'money' | translate }}</p>
          </div>
        </div>
        <div class="container-footer">
          <div class="status">
            <p class="text-style text-status">{{ 'collection.status' | translate }}</p>
            <p class="text-style">
              <span *ngIf="one.status == 'CONFIRM'" class="status-check">перевірено</span>
              <span *ngIf="one.status == 'CREATE'" class="status-not-check">неперевірено</span>
            </p>
          </div>
          <div class="date-time">
            <p class="text-style" style="margin-right: 10px">{{ one.date | localdate }}</p>
            <p class="text-style">{{ one.date | localtime }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cont-tickets" *ngIf="collections && step == 1">
      <div class="one-tic" *ngFor="let one of collections.content">
        <div class="tick">
          <div class="open-close border">
            <svg
              width="10"
              height="14"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              id="transf{{ one.id }}"
              (click)="togggleDetails('detail' + one.id, 'transf' + one.id)"
            >
              <path d="M9.51473 7L0.34375 14L0.343749 0L9.51473 7Z" fill="#C4C4C4" />
            </svg>
          </div>
          <div class="name border">
            <p class="headers">{{ 'collection-one.name-cas' | translate }}</p>
            <div class="name-cas">
              <p valueByLang [dictionary]="one.boxOfficeName"></p>
            </div>
          </div>
          <div class="return-tick border">
            <p class="headers">{{ 'collection-one.return-tick' | translate }}</p>
            <svg
              width="17"
              height="15"
              viewBox="0 0 29 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="eye-img"
              (click)="openClosePopUp('popUp', true, 0, one.id)"
            >
              <path
                d="M14.4911 0C8.12448 0 2.63729 3.90066 0.116655 9.52958C0.03924 9.70016 0.03924 9.89969 0.116655 10.0735C2.63729 15.7024 8.12451 19.6031 14.4911 19.6031C20.8577 19.6031 26.3449 15.7024 28.8655 10.0735C28.943 9.90291 28.943 9.70337 28.8655 9.52958C26.3449 3.90066 20.8577 0 14.4911 0ZM14.4911 16.565C10.8959 16.565 7.98513 13.5364 7.98513 9.80314C7.98513 6.06662 10.899 3.04136 14.4911 3.04136C18.0863 3.04136 20.9971 6.06984 20.9971 9.80314C20.9971 13.5364 18.0832 16.565 14.4911 16.565Z"
                fill="#2D9CDB"
              />
              <path
                d="M14.4892 14.1281C16.7894 14.1281 18.6541 12.1901 18.6541 9.79941C18.6541 7.40873 16.7894 5.4707 14.4892 5.4707C12.1889 5.4707 10.3242 7.40873 10.3242 9.79941C10.3242 12.1901 12.1889 14.1281 14.4892 14.1281Z"
                fill="#2D9CDB"
              />
            </svg>
            <div class="return-ticket-one">
              <div class="kilkist border">
                <p>{{ one.revertTicketsCount }} {{ 'collection-one.kilk' | translate }}</p>
              </div>
              <div class="kilkist">
                <p>{{ one.priceMinus }} {{ 'collection-one.currency' | translate }}</p>
              </div>
            </div>
          </div>
          <div class="return-tick border">
            <p class="headers">{{ 'collection-one.sold-tick' | translate }}</p>
            <svg
              width="17"
              height="15"
              viewBox="0 0 29 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="eye-img"
              (click)="openClosePopUp('popUp', true, 1, one.id)"
            >
              <path
                d="M14.4911 0C8.12448 0 2.63729 3.90066 0.116655 9.52958C0.03924 9.70016 0.03924 9.89969 0.116655 10.0735C2.63729 15.7024 8.12451 19.6031 14.4911 19.6031C20.8577 19.6031 26.3449 15.7024 28.8655 10.0735C28.943 9.90291 28.943 9.70337 28.8655 9.52958C26.3449 3.90066 20.8577 0 14.4911 0ZM14.4911 16.565C10.8959 16.565 7.98513 13.5364 7.98513 9.80314C7.98513 6.06662 10.899 3.04136 14.4911 3.04136C18.0863 3.04136 20.9971 6.06984 20.9971 9.80314C20.9971 13.5364 18.0832 16.565 14.4911 16.565Z"
                fill="#2D9CDB"
              />
              <path
                d="M14.4892 14.1281C16.7894 14.1281 18.6541 12.1901 18.6541 9.79941C18.6541 7.40873 16.7894 5.4707 14.4892 5.4707C12.1889 5.4707 10.3242 7.40873 10.3242 9.79941C10.3242 12.1901 12.1889 14.1281 14.4892 14.1281Z"
                fill="#2D9CDB"
              />
            </svg>
            <div class="return-ticket-one">
              <div class="kilkist border">
                <p>
                  <!--<span style="color:#2CAF39"></span>-->
                  <!--<span style="color:red"></span>-->
                  {{ one.countTicket }} {{ 'collection-one.kilk' | translate }}
                </p>
              </div>
              <div class="kilkist">
                <p>{{ one.price }} {{ 'collection-one.currency' | translate }}</p>
              </div>
            </div>
          </div>
          <div class="cashier border">
            <p class="headers">{{ 'collection-one.cash' | translate }}</p>
            <div class="center">
              <p class="green-text">{{ one.priceCash }} {{ 'collection-one.currency' | translate }}</p>
            </div>
          </div>
          <div class="cashier border">
            <p class="headers">{{ 'collection-one.term' | translate }}</p>
            <div class="center">
              <p class="green-text">{{ one.priceCard }} {{ 'collection-one.currency' | translate }}</p>
            </div>
          </div>
          <div class="cashier border">
            <p class="headers">{{ 'collection-one.all' | translate }}</p>
            <div class="center">
              <p class="green-text">{{ one.priceBoxOffice + one.price }} {{ 'collection-one.currency' | translate }}</p>
            </div>
          </div>
          <div class="cashier border">
            <p class="headers">{{ 'collection-one.time' | translate }}</p>
            <div class="center-time">
              <div class="date">
                <p class="blue-text">{{ one.date | localdate }}</p>
              </div>
              <div class="date">
                <p class="blue-text">{{ one.date | localtime }}</p>
              </div>
            </div>
          </div>
          <div class="cashier">
            <p class="headers">{{ 'collection-one.status' | translate }}</p>
            <div class="center">
              <p *ngIf="one.status == 'CONFIRM'" class="green-checked">{{ 'collection-one.checked' | translate }}</p>
              <p *ngIf="one.status == 'CREATE'" class="green-checked">{{ 'collection-one.unchecked' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="cont-details" id="detail{{ one.id }}">
          <div class="cashier-address border">
            <div class="cashierss">
              <div class="addr border">
                <p class="headers">{{ 'collection-one.cashier' | translate }}</p>
              </div>
              <div class="cashier-details">
                <p>{{ one.user.firstName }} {{ one.user.lastName }}</p>
              </div>
            </div>
            <div class="address">
              <div class="addr border">
                <p class="headers">{{ 'collection-one.address' | translate }}</p>
              </div>
              <div class="address-details">
                <p valueByLang [dictionary]="one.address"></p>
              </div>
            </div>
          </div>
          <div class="number-cont">
            <div class="number-header border">
              <p class="headers">{{ 'collection-one.number' | translate }}</p>
            </div>
            <div class="number-details">
              <p>{{ one.id }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="collections" class="container_pageable">
      <app-pageable [page]="collections" (countOnPage)="changeCount($event)" (openPage)="openPage($event)" [range]="4"></app-pageable>
    </div>
  </div>
  <div class="footer">
    <div class="button-collect">
      <button class="text-style" (click)="collectBox()">{{ 'collection.collect' | translate }}</button>
    </div>
    <div class="collect-foot">
      <div class="tickets-green">
        <p class="text-style">{{ 'collection.tickets' | translate }}</p>
        <p class="text-style bold">{{ mainInfo.countTicket }} шт</p>
      </div>
      <div class="tickets-blue">
        <p class="text-style">{{ 'collection.cash_two' | translate }}</p>
        <p class="text-style bold">{{ mainInfo.priceCash }} {{ 'money' | translate }}</p>
      </div>
      <div class="tickets-green">
        <p class="text-style">{{ 'collection.terminal_two' | translate }}</p>
        <p class="text-style bold">{{ mainInfo.priceCard }} {{ 'money' | translate }}</p>
      </div>
      <div class="tickets-blue">
        <p class="text-style">{{ 'collection.suma_sold_tick' | translate }}</p>
        <p class="text-style bold">{{ mainInfo.allPrice }} {{ 'money' | translate }}</p>
        <span style="color: #cb2969">(-{{ mainInfo.priceMinus }}{{ 'money' | translate }})</span>
      </div>
    </div>
  </div>
  <div class="container-popUp" id="popUp">
    <div class="dark" *ngIf="popUpMain">
      <div class="tick-pop">
        <!--close-->
        <div class="close">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            (click)="openClosePopUp('popUp', false)"
          >
            <path d="M2 2.00195L13.9977 13.9996" stroke="#4D4A4A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14 2.00195L2.00034 13.9969" stroke="#4D4A4A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="tickets-list">
          <div class="tickets-green">
            <p class="text-style">{{ 'collection.tickets' | translate }}</p>
            <p class="text-style bold">{{ popUpMain.countTicketMinus }} шт</p>
          </div>

          <div class="tickets-blue">
            <p class="text-style">{{ 'collection.cash_two' | translate }}</p>
            <p class="text-style bold">{{ popUpMain.priceCash }}{{ 'money' | translate }}</p>
          </div>

          <div class="tickets-green">
            <p class="text-style">{{ 'collection.terminal_two' | translate }}</p>
            <p class="text-style bold">{{ popUpMain.priceCard }}{{ 'money' | translate }}</p>
          </div>

          <div class="tickets-blue tickets-blue-none">
            <p class="text-style">{{ 'collection.suma_sold_tick' | translate }}</p>
            <p class="text-style bold">{{ popUpMain.allPrice }}{{ 'money' | translate }}</p>
          </div>
        </div>
        <div class="cont-ticks">
          <div class="one-ticket" *ngFor="let one of popUpMain.ticketCollections">
            <div class="tick-header">
              <p class="text-style">{{ one.event.name.valueUA }}</p>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: flex-start; width: 100%">
              <div class="about-event">
                <div class="section">
                  <p class="text-style about-header">{{ 'collection.type' | translate }}</p>
                  <div class="about-text">
                    <p class="text-style" *ngIf="one.sector">{{ one.sector }}</p>
                    <p class="nan-text-style" *ngIf="!one.sector">{{ 'collection.nan_type' | translate }}</p>
                  </div>
                </div>
                <div class="section">
                  <p class="text-style about-header">{{ 'collection.row' | translate }}</p>
                  <div class="about-text">
                    <p class="text-style" *ngIf="one.row">{{ one.row }}</p>
                    <p class="nan-text-style" *ngIf="!one.row">{{ 'collection.nan_row' | translate }}</p>
                  </div>
                </div>
                <div class="section">
                  <p class="text-style about-header">{{ 'collection.place' | translate }}</p>
                  <div class="about-text">
                    <p class="text-style" *ngIf="one.seatPosition">{{ one.seatPosition }}</p>
                    <p class="nan-text-style" *ngIf="!one.seatPosition">{{ 'collection.nan_place' | translate }}</p>
                  </div>
                </div>
                <div class="section">
                  <p class="text-style about-header">{{ 'collection.price' | translate }}</p>
                  <div class="about-text">
                    <p class="text-style">{{ one.price }}{{ 'money' | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: flex-start; width: 100%">
              <div class="about-event">
                <div class="section">
                  <p class="text-style about-header">{{ 'collection.date' | translate }}</p>
                  <div class="about-text">
                    <p class="text-style">{{ one.event.beginDate | localdate }}, {{ one.event.beginDate | localtime }}</p>
                  </div>
                </div>
                <div class="section">
                  <p class="text-style about-header">{{ 'collection.city' | translate }}</p>
                  <div class="about-text">
                    <p class="text-style" valueByLang [dictionary]="one.event.platformHall.city.dictionary"></p>
                  </div>
                </div>
                <div class="price-tick">
                  <p class="text-style">{{ one.finalPrice }}</p>
                  <p class="text-style">{{ 'money' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
